// 全局混合
import Vue from 'vue'
import globalVar from '../configs/globalVar'
import staticData from '../configs/data'

const mixin = {
  computed: {
    // 全局变量
    globalVar () {
      return globalVar
    },
    // 静态数据
    staticData () {
      return staticData
    },
    // 当前用户信息
    userInfo () {
      return this.$store.state.userInfo
    },
    // 当前角色
    role () {
      return this.userInfo.role
    }
  }
}

Vue.mixin(mixin)
