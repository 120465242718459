import cities from '../assets/json/cities.json'

// 省份列表
const provinceList = cities.map(item => item.label)
provinceList.unshift('不限')

// 专家团队 -> 服务类型
const fwlx = ['技术服务', '企业管理', '创业指导', '科研创新', '其他']

// 成果 -> 成果类型
const cglx = ['发明专利', '实用新型专利', '软件著作权', '著作权', '商标权', '外观设计', '植物新品种', '其他技术成果']

// 成果 -> 成熟度
const csd = ['报告级', '方案级', '功能级', '仿真级', '初样级', '正样级', '环境级', '产品级', '系统级', '销售级']

// 成果 -> 合作方式
const hzfs = ['技术转让', '专利许可', '委托开发', '合作开发', '技术咨询', '技术服务', '技术入股', '创业融资', '股权融资']

export default {
  cities,
  provinceList,
  fwlx,
  cglx,
  csd,
  hzfs
}
