<template>
  <div class="header">
    <div class="header_content page_width tw-flex tw-justify-between tw-items-center">
      <div class="header_content_left tw-flex tw-items-center">
        <img class="header_content_logo" src="../assets/images/logo.png" alt="">
        <div class="header_content_title tw-flex tw-items-center">
          <p class="header_content_title-main text_size_18 tw-font-bold">共创云后台管理系统</p>
          <p class="header_content_title-sub text_size_14 tw-font-bold">{{title}}</p>
        </div>
      </div>
      <p class="header_content_nav text_size_14" @click="toIndex">平台首页</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    // 需要根据角色去不同路由
    toIndex () {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 100px;
  background: linear-gradient(88deg, #F4F5F7 0%, #F4F5F7 52%, rgba(244, 245, 247, 0) 100%),
    url('../assets/images/detail_header_bg.png') no-repeat top right;
  &_content {
    height: 100%;
    padding: 0 20px;
    // &_left {

    // }
    &_logo {
      margin-right: 10px;
      width: 40px;
    }
    &_title {
      &-main {
        position: relative;
        line-height: 25px;
        color: #000000;
        margin-right: 21px;
        &::after {
          content: "";
          position: absolute;
          width: 1px;
          height: 20px;
          right: -10px;
          top: 50%;
          transform: translateY(-50%);
          background-color: #DCDFE6;
        }
      }
      &-sub {
        line-height: 20px;
        color: #303133;
      }
    }
    &_nav {
      cursor: pointer;
      color: #303133;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
