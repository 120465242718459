
import Vue from 'vue'
import Vuex from 'vuex'

import * as api from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
    // 技术领域下拉列表
    technicalList: [],
    // 行业领域下拉列表
    industrySectorList: [],
    // 表头样式
    headerCellStyle: {
      backgroundColor: '#F7F8F9',
      color: '#909399',
      fontWeight: 'bold'
    }
  },
  mutations: {
    setUserInfo (state, data) {
      state.userInfo = data
      localStorage.setItem('userInfo', JSON.stringify(data))
    },
    // 设置技术领域下拉列表
    setTechnicalList (state, data) {
      state.technicalList = data
    },
    // 设置行业领域下拉列表
    setIndustrySectorList (state, data) {
      state.industrySectorList = data
    }
  },
  actions: {
    getUserInfo ({ commit }) {
      api.getUserInfo().then(res => {
        if (res.data.code === 0) {
          commit('setUserInfo', res.data.data)
        }
      })
    },
    // 获取技术领域下拉框列表
    getTechnicalList ({ commit }) {
      return new Promise((resolve, reject) => {
        api.getTechnicalList().then(res => {
          if (res.data.code === 0) {
            commit('setTechnicalList', res.data.data.list)
            resolve()
          } else {
            console.error('获取技术领域下拉框列表错误')
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 获取行业领域下拉框列表
    getIndustrySectorList ({ commit }) {
      return new Promise((resolve, reject) => {
        api.getIndustrySectorList().then(res => {
          if (res.data.code === 0) {
            commit('setIndustrySectorList', res.data.data.list)
            resolve()
          } else {
            console.error('获取行业领域下拉框列表错误')
          }
        }).catch(err => {
          reject(err)
        })
      })
    }
  },
  modules: {
  }
})
