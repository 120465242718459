import api from '@/configs/api'

/**
 * 文件上传地址
 */
export const uploadURL = api.baseUrl + 'api/v1/uploads'

/**
 * 文件上传
 * @param {*} data
 */
export const uploadFile = (data) => api.upload('api/v1/uploads', data)

/**
 * 管理员登录
 * @param {*} data
 */
export const login = (data) => api.post('admin/api/v1/auth/login', data)

/**
 * 获取用户信息
 * @param {*} data
 */
export const getUserInfo = (data) => api.get('admin/api/v1/user/me', data)

/**
 * 获取后台账号列表
 * @param {*} role
 * @param {*} data
 */
export const getAccountList = (role, data) => api.get(`admin/api/v1/account/${role}/list`, data)

/**
 * 获取后台账号详情
 * @param {*} id
 * @param {*} data
 */
export const getAccountInfo = (id) => api.get(`admin/api/v1/account/${id}`)

/**
 * 添加后台账号
 * @param {*} data
 */
export const createAccount = (data) => api.post('admin/api/v1/account', data)

/**
 * 修改后台账号信息
 * @param {*} id
 * @param {*} data
 */
export const editAccountInfo = (id, data) => api.put(`admin/api/v1/account/${id}`, data)

/**
 * 启用、禁用后台账号
 * @param {*} id
 * @param {*} data
 */
export const toggleAccountEnabled = (id, data) => api.put(`admin/api/v1/account/${id}/enabled`, data)

/**
 * 获取机构账号列表
 * @param {*} role
 * @param {*} data
 */
export const getUserAccountList = (role, data) => api.get(`admin/api/v1/user/${role}/list`, data)

/**
 * 获取已驳回的机构认证记录
 * @param {*} data
 */
export const getRejectedUserList = (data) => api.get('admin/api/v1/account/rejected_list', data)

/**
 * 更改机构账号审核状态
 * @param {*} id
 * @param {*} data
 */
export const editUserAccountStatus = (id, data) => api.put(`admin/api/v1/user/${id}/logic_status`, data)

/**
 * 启用、禁用用户账号
 * @param {*} id
 * @param {*} data
 */
export const toggleUserAccountEnabled = (id, data) => api.put(`admin/api/v1/user/${id}/enabled`, data)

/**
 * 获取机构账号的详情
 * @param {*} id
 * @param {*} data
 */
export const getUserAccountInfo = (id, data) => api.get(`admin/api/v1/user/${id}`, data)

/**
 * 获取行业领域列表
 * @param {*} data
 */
export const getIndustrySectorList = (data) => api.get('api/v1/industry/list', data)

/**
 * 获取技术领域
 * @param {*} data
 */
export const getTechnicalList = (data) => api.get('api/v1/technical/list', data)

/**
 * 获取栏目列表
 * @param {*} data
 */
export const getInfoColumn = (data) => api.get('api/v1/news/column', data)

/**
 * 获取某一栏目下的列表
 * @param {*} id
 * @param {*} data
 */
export const getInfoList = (id, data) => api.get(`admin/api/v1/news/${id}/list`, data)

/**
 * 获取全部资讯列表
 * @param {*} data
 */
export const getAllInfoList = (data) => api.get('admin/api/v1/news/all/list', data)

/**
 * 获取资讯详情
 * @param {*} id
 * @param {*} data
 */
export const getInfoDetail = (id, data) => api.get(`api/v1/news/${id}/details`, data)

/**
 * 审核资讯
 * @param {*} id
 * @param {*} data
 */
export const checkInfo = (id, data) => api.put(`admin/api/v1/news/${id}/status`, data)

/**
 * 给某一栏目添加一个新记录
 * @param {*} data
 */
export const addInfo = (data) => api.post('admin/api/v1/news', data)

/**
 * 编辑某个内容
 * @param {*} data
 */
export const updateInfo = (id, data) => api.put(`admin/api/v1/news/${id}`, data)

/**
 * 删除某一条内容
 * @param {*} id
 * @param {*} data
 */
export const deleteInfo = (id, data) => api.remove(`admin/api/v1/news/${id}`, data)

/**
 * 获取首页数据合计统计
 * @param {*} data
 */
export const getDatasum = (data) => api.get('admin/v1/dataset/dashboard/datasum', data)

/**
 * 获取首页交易量统计
 * @param {*} data
 */
export const getTraded = (data) => api.get('admin/v1/dataset/dashboard/traded', data)

/**
 * 获取活跃度图表统计
 * @param {*} data
 */
export const getActiveChartData = (data) => api.get('admin/v1/chart/broker_home/activity', data)

/**
 * 获取资讯管理数据统计
 * @param {*} data
 */
export const getInfoChartData = (data) => api.get('admin/api/v1/news/dashboard', data)

/**
 * 获取撮合记录列表
 * @param {*} data
 */
export const getTradeMatchData = (data) => api.get('admin/v1/logs/action/trade_match/list', data)

/**
 * 获取服务类型下拉列表
 * 可以传parentId获取二级服务列表
 * 也可以传入level 获取具体的层级
 * @param {*} data
 */
export const getServiceTypeOptions = (data) => api.get('admin/api/v1/service_type/downlist', data)

/**
 * 获取服务列表
 * 可以用关键词、服务状态、服务类型作为筛选条件
 * @param {*} data
 */
export const getServiceList = (data) => api.get('admin/api/v1/service/list', data)

/**
 * 获取需求列表
 * 可以用关键词、服务状态、服务类型作为筛选条件
 * @param {*} data
 */
export const getRequireList = (data) => api.get('admin/api/v1/requirements', data)

/**
 * 获取需求分配列表
 * @param {*} data
 * @returns
 */
export const getReviewRequireList = (data) => api.get('admin/api/v1/requirements/distribution', data)

/**
 * 获取需求详情
 * @param {*} reqId
 * @param {*} data
 */
export const getRequireDetail = (id, data) => api.get(`api/v1/requirement/${id}/result`, data)

/**
 * 更改需求状态
 * @param {*} reqId
 * @param {*} data
 */
export const editRequireStatus = (id, data) => api.put(`api/v1/requirement/${id}/status`, data)

/**
 * 后台代发需求
 * @param {*} data
 */
export const createRequirement = (data) => api.post('api/v1/requirement/proxy', data)

/**
 * 获取服务类型列表
 * @param {*} data
 */
export const getServiceTypeList = (data) => api.get('admin/api/v1/service_type/list', data)

/**
 * 添加服务类型
 * @param {*} data
 */
export const addService = (data) => api.post('admin/api/v1/service_type', data)

/**
 * 更新服务的自定义表单
 * @param {*} data
 */
export const updateCustomForm = (data) => api.put('admin/api/v1/form/modify', data)

/**
 * 添加服务类型
 * @param {*} data
 */
export const addServiceProcess = (data) => api.post('admin/api/v1/process', data)

/**
 * 服务表单id 获取服务类型所关联的表单详情
 * @param {*} data
 */
export const getFormDetail = (id, data) => api.get(`admin/api/v1/form/${id}`, data)

/**
 * 切换服务类型启用禁用状态
 * @param {*} id
 * @param {*} data
 */
export const toggleServiceTypeEnabled = (id, data) => api.put(`admin/api/v1/service_type/${id}/enable`, data)

/**
 * 获取服务链列表
 * @param {*} data
 */
export const getServiceChainList = (data) => api.get('admin/api/v1/service_chain/list', data)

/**
 * 获取服务类型两级结构的列表
 * @param {*} data
 */
export const getServiceTypeCheckList = (data) => api.get('admin/api/v1/service_type/ladderlist', data)

/**
 * 发布服务链
 * @param {*} data
 */
export const createServiceChain = (data) => api.post('admin/api/v1/service_chain', data)

/**
 * 获取服务链详情
 * @param {*} id
 * @param {*} data
 */
export const getServiceChainDetail = (id, data) => api.get(`admin/api/v1/service_chain/${id}/details`, data)

/**
 * 禁用或启用服务链
 * @param {*} id
 * @param {*} data
 */
export const toggleServiceChainStatus = (id, data) => api.put(`admin/api/v1/service_chain/${id}/enabled`, data)

/**
 * 修改服务链详情
 * @param {*} id
 * @param {*} data
 */
export const editServiceChain = (id, data) => api.put(`admin/api/v1/service_chain/${id}`, data)

/**
 * 获取服务详情
 * @param {*} id
 * @param {*} data
 */
export const getServiceDetail = (id, data) => api.get(`admin/api/v1/service/${id}/details`, data)

/**
 * 更改服务状态
 * @param {*} id
 * @param {*} data
 */
export const editServiceStatus = (id, data) => api.put(`admin/api/v1/service/${id}/logic_status`, data)

/**
 * 获取机构信用列表
 * @param {*} data
 */
export const searchOrg = (data) => api.get('admin/api/v1/organization/credit/list', data)

/**
 * 获取订单列表
 * @param {*} data
 */
export const getOrderList = (data) => api.get('admin/api/v1/order/list', data)

/**
 * 获取订单详情
 * @param {*} data
 */
export const getOrderDetail = (id, data) => api.get(`admin/api/v1/order/${id}/info`, data)

/**
 * 获取订单服务详情
 * @param {*} id
 * @param {*} data
 */
export const getOrderServiceDetail = (id, data) => api.get(`admin/api/v1/order/${id}/details`, data)

/**
 * 删除服务类型
 * @param {*} serviceTypeId
 * @param {*} data
 */
export const deleteServiceType = (serviceTypeId, data) => api.remove(`admin/api/v1/service_type/${serviceTypeId}`, data)

/**
 * 获取订单评价列表
 * @param {*} data
 */
export const getOrderCommentList = (data) => api.get('admin/api/v1/order/feedback/list', data)

/**
 * 通过订单评价，显示在服务的详情页中
 * @param {*} feedbackId
 * @param {*} data
 */
export const passOrderComment = (feedbackId, data) => api.put(`admin/api/v1/order/feedback/${feedbackId}/pass`, data)

/**
 * 获取订单评价详情
 * @param {*} feedbackId
 * @param {*} data
 */
export const getOrderCommentInfo = (feedbackId, data) => api.get(`admin/api/v1/order/feedback/${feedbackId}/info`, data)

/**
 * 后台代发创建服务
 * @param {*} data
 */
export const createService = (data) => api.post('admin/api/v1/service', data)

/**
 * 通过服务类型id 来获取表单
 * @param {*} data
 */
export const getFormByService = (id, data) => api.get(`admin/api/v1/form/${id}/by_service`, data)

/**
 * 获取全部前台用户列表
 * @param {*} data
 */
export const getAllUserList = (data) => api.get('admin/api/v1/user/list', data)

/**
 * 获取前台用户基本资料
 * @param {*} data
 */
export const getUserItemInfo = (userId, data) => api.get(`admin/api/v1/user/${userId}/details`, data)

/**
 * 获取服务用户入驻申请列表
 * @param {*} data
 */
export const getOrgRzAuditList = (data) => api.get('admin/api/v1/organization/register/review/list', data)

/**
 * 获取机构信息
 * @param {*} organizationId
 * @param {*} data
 */
export const getOrgRzAuditDetail = (organizationId, data) => api.get(`api/v1/organization/${organizationId}/info`, data)

/**
 * 机构信息审核，通过或驳回
 * @param {*} organizationId
 * @param {*} data
 */
export const auditRzDetail = (userId, data) => api.put(`admin/api/v1/organization/register/review/${userId}`, data)

/**
 * 获取集成商认证列表
 * @param {*} data
 */
export const getOrgJcsAuditList = (data) => api.get('admin/api/v1/organization/certification/review/list', data)

/**
 * 集成商认证审核，通过或驳回
 * @param {*} organizationId
 * @param {*} data
 */
export const auditJcsDetail = (userId, data) => api.put(`admin/api/v1/organization/certification/review/${userId}`, data)

/**
 * 获取专家团队列表
 * @param {*} data
 */
export const getExpertTeamList = (data) => api.get('admin/api/v1/expert/list', data)

/**
 * 后台代发专家
 * @param {*} data
 */
export const createExpert = (data) => api.post('admin/api/v1/expert', data)

/**
 * 获取专家团队详情
 * @param {*} expertId
 * @param {*} data
 */
export const getExpertTeamDetail = (expertId, data) => api.get(`api/v1/expert/${expertId}/details`, data)

/**
 * 专家团队信息审核
 * @param {*} expertId
 * @param {*} data
 */
export const auditExpertTeamDetail = (expertId, data) => api.put(`admin/api/v1/expert/review/${expertId}`, data)

/**
 * 获取用户所有服务的评价反馈列表
 * @param {*} userId
 * @param {*} data
 */
export const getUserCommentList = (userId, data) => api.get(`api/v1/order/feedback/${userId}/list`, data)

/**
 * 修改平台打分
 * @param {*} organizationId
 * @param {*} data
 */
export const editPlatformScore = (organizationId, data) => api.put(`admin/api/v1/organization/platform/${organizationId}`, data)

/**
 * 获取用户举报记录列表
 * @param {*} data
 * @returns
 */
export const getUserReportList = (data) => api.get('api/v1/complaint/list', data)

/**
 * 获取需求订单详情
 * @param {*} orderId
 * @param {*} data
 * @returns
 */
export const getRequireOrderDetail = (orderId, data) => api.get(`api/v1/requirement/order/${orderId}`, data)

/**
 * 获取需求订单列表
 * @param {*} data
 * @returns
 */
export const getRequireOrderList = (data) => api.get('admin/api/v1/requirement/order/list', data)

/**
 * 通过关键字来获取下拉列表
 * @param {*} data
 * @returns
 */
export const getKeywordsDownOrgList = (data) => api.get('api/v1/organization/down_list', data)

/**
 * 获取成果列表
 * @param {*} data
 * @returns
 */
export const getAchievementList = (data) => api.get('admin/api/v1/achievements', data)

/**
 * 更改成果状态
 * @param {*} data
 * @returns
 */
export const updateAchievementStatus = (achievementId, data) => api.put(`admin/api/v1/achievement/${achievementId}/modify_status`, data)

/**
 * 发布成果
 * @param {*} data
 * @returns
 */
export const createAchievement = (data) => api.post('admin/api/v1/achievement', data)

/**
 * 获取成果详情
 * @param {*} data
 * @returns
 */
export const getAchievementDetail = (achievementId, data) => api.get(`api/v1/achievement/${achievementId}`, data)

/**
 * 获取服务用户、服务集成商下拉列表
 * @param {*} data
 * @returns
 */
export const getUserListByRole = (data) => api.get('api/v1/provider/downlist', data)

/**
 * 客服选择直接发布需求到大厅
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const releaseRequirement = (id, data) => api.put(`admin/api/v1/requirement/${id}/published`, data)

/**
 * 客服分配需求给服务用户或者服务提供商
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const allocateRequirement = (id, data) => api.post(`admin/api/v1/requirement/${id}/provider`, data)

/**
 * 管理端获取资源详情
 * @param {*} id
 * @param {*} data
 */
export const getRequireFormDetail = (id, data) => api.get(`api/v1/requirement/${id}/result`, data)

/**
 * 获取子需求列表
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const getSubRequireList = (id, data) => api.get(`api/v1/requirements/${id}/subrequirement`, data)

/**
 * 成果导出
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const achievementExport = (data) => api.get('admin/api/v1/achievement/exports', data)

/**
 * 编辑成果
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const updateAchievement = (id, data) => api.put(`api/v1/achievement/${id}`, data)

/**
 * 需求导出
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const requireExport = (data) => api.get('admin/api/v1/requirement/exports', data)

/**
 * 修改需求
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const updateRequire = (id, data) => api.put(`api/v1/requirement/${id}`, data)

/**
 * 根据机构id 获取到机构下人员列表及评价信息
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const getOrgCreditMemberList = (orgId, data) => api.get(`admin/api/v1/organization/${orgId}/credit_member/list`, data)

/**
 * 获取制动机构用户下的服务评论列表
 * @param {*} userId
 * @param {*} data
 * @returns
 */
export const getOrgMemberCommentList = (userId, data) => api.get(`api/v1/order/feedback/${userId}/list`, data)

/**
 * 获取指定机构用户下评分统计图
 * @param {*} userId
 * @param {*} data
 * @returns
 */
export const getOrgMemberCreditStatistic = (userId, data) => api.get(`admin/api/v1/user/${userId}/credit_statistic`, data)

/**
 * 更新服务
 * @param {*} serviceId
 * @param {*} data
 * @returns
 */
export const updateService = (serviceId, data) => api.put(`api/v1/service/${serviceId}`, data)

/**
 * 导出服务
 * @param {*} serviceId
 * @param {*} data
 * @returns
 */
export const exportService = (data) => api.get('api/v1/service/exports', data)

/**
 * 导出我审核的服务
 * @param {*} data
 * @returns
 */
export const exportMyReviewService = (data) => api.get('admin/api/v1/service/exports', data)

/**
 * 机构入驻
 * 后台获取我的审核通过的入驻记录列表
 * @param {*} data
 * @returns
 */
export const getMyReviewLogByRZ = (data) => api.get('admin/api/v1/organization/register/review_log/list', data)

/**
 * 集成商
 * 后台获取我的审核通过的服务集成商记录列表
 * @param {*} data
 * @returns
 */
export const getMyReviewLogByJCS = (data) => api.get('admin/api/v1/organization/certification/review_log/list', data)

/**
 * 服务列表
 * 后台获取我审核的服务列表
 * @param {*} data
 * @returns
 */
export const getMyReviewLogByService = (data) => api.get('admin/api/v1/service/audit_list', data)

/**
 * 获取资源池地址
 * @param {*} data
 * @returns
 */
export const getResourcepoolURL = (data) => api.get('api/v1/system/resourcepool/info', data)

/**
 * 导出全部用户
 * @param {*} data
 * @returns
 */
export const exportAllUser = (data) => api.get('admin/api/v1/user/exports', data)

/**
 * 导出机构入驻列表
 * @param {*} data
 * @returns
 */
export const exportRzList = (data) => api.get('admin/api/v1/organization/register/review/exports', data)

/**
 * 导出我的审核机构入驻列表
 * @param {*} data
 * @returns
 */
export const exportMyReviweRzList = (data) => api.get('admin/api/v1/organization/register/review_log/exports', data)

/**
 * 获取我审核的需求列表
 * @param {*} data
 * @returns
 */
export const getMyReviewRequireList = (data) => api.get('admin/api/v1/requirements/audit_list', data)

/**
 * 获取我审核的成果列表
 * @param {*} data
 * @returns
 */
export const getMyReviewAchievementsList = (data) => api.get('admin/api/v1/achievements/audit_list', data)
