import cookie from '@/configs/cookie'
import globalVar from '@/configs/globalVar'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // 登录
  {
    path: '/login',
    component: () => import('../views/login/index.vue')
  },
  // 机构信用与评价详情
  {
    path: '/org-credit/detail/:id',
    component: () => import('../views/org-credit/detail.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.ADMIN_ROLE_0]
    }
  },
  // 用户账号基本资料
  {
    path: '/account-manage/user-list/detail/:id',
    component: () => import('../views/account-manage/user-list/detail.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1, globalVar.ADMIN_ROLE_2]
    }
  },
  // 用户机构入驻资料
  {
    path: '/account-manage/rz-audit/detail/:id',
    component: () => import('../views/account-manage/rz-audit/detail.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1, globalVar.ADMIN_ROLE_2]
    }
  },
  // 集成商认证资料
  {
    path: '/account-manage/jcs-audit/detail/:id',
    component: () => import('../views/account-manage/jcs-audit/detail.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
    }
  },
  // 后台运营账号 添加、修改
  {
    path: '/account-manage/admin-manage/detail/:id?',
    component: () => import('../views/account-manage/admin-manage/detail.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
    }
  },
  // 服务详情 查看、审核
  {
    path: '/resource-require/service/detail/:id',
    component: () => import('../views/resource-require/service/detail.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1, globalVar.ADMIN_ROLE_2]
    }
  },
  // 需求详情 查看
  {
    path: '/resource-require/require/detail/:id',
    component: () => import('../views/resource-require/require/detail/index.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1, globalVar.ADMIN_ROLE_2]
    }
  },
  // 需求详情 分配
  {
    path: '/resource-require/require/detail-review/:id',
    component: () => import('../views/resource-require/require/detail/review.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1, globalVar.ADMIN_ROLE_2]
    }
  },
  // 专家详情 查看、审核
  // {
  //   path: '/resource-require/expert/detail/:id',
  //   component: () => import('../views/resource-require/expert/detail.vue'),
  //   meta: {
  //     requiresAuth: true,
  //     role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
  //   }
  // },
  // 成果详情 查看、审核
  {
    path: '/resource-require/achievement/detail/:id',
    component: () => import('../views/resource-require/achievement/detail.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1, globalVar.ADMIN_ROLE_2]
    }
  },
  // 后台创建服务
  {
    path: '/resource-require/service/create',
    component: () => import('../views/resource-require/service/create.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1, globalVar.ADMIN_ROLE_2]
    }
  },
  // 后台编辑服务
  {
    path: '/resource-require/service/edit/:id',
    component: () => import('../views/resource-require/service/edit.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1, globalVar.ADMIN_ROLE_2]
    }
  },
  // 后台发布需求
  {
    path: '/resource-require/require/create',
    component: () => import('../views/resource-require/require/create/index.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1, globalVar.ADMIN_ROLE_2]
    }
  },
  // 后台编辑需求
  {
    path: '/resource-require/require/edit/:id',
    component: () => import('../views/resource-require/require/edit/index.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1, globalVar.ADMIN_ROLE_2]
    }
  },
  // 后台新增专家
  // {
  //   path: '/resource-require/expert/create',
  //   component: () => import('../views/resource-require/expert/create.vue'),
  //   meta: {
  //     requiresAuth: true,
  //     role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
  //   }
  // },
  // 后台发布成果
  {
    path: '/resource-require/achievement/create',
    component: () => import('../views/resource-require/achievement/create.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1, globalVar.ADMIN_ROLE_2]
    }
  },
  // 后台编辑成果
  {
    path: '/resource-require/achievement/edit/:id',
    component: () => import('../views/resource-require/achievement/edit.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1, globalVar.ADMIN_ROLE_2]
    }
  },
  // 服务类型维护 -> 添加服务类型
  {
    path: '/resource-require/service-type-manage/add-service-type',
    component: () => import('../views/resource-require/service-type-manage/add-service-type.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
    }
  },
  // 服务类型维护 -> 编辑服务类型表单字段
  {
    path: '/resource-require/service-type-manage/edit-service-type/:formId',
    component: () => import('../views/resource-require/service-type-manage/detail.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
    }
  },
  // 定制服务链
  {
    path: '/service-chain/:type/:id?',
    component: () => import('../views/service-chain-manage/service-chain-detail'),
    meta: {
      requiresAuth: true,
      role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
    }
  },
  // 服务订单详情
  {
    path: '/order-manage/service-order/detail/:id',
    component: () => import('../views/order-manage/service-order/detail/index.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
    }
  },
  // 需求订单详情
  {
    path: '/order-manage/require-order/detail/:id',
    component: () => import('../views/order-manage/require-order/detail/index.vue'),
    meta: {
      requiresAuth: true,
      role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
    }
  },
  // 资讯列表详情、资讯审核详情并用
  {
    path: '/info-manage/detail/:id',
    component: () => import('../views/info-manage/detail/index.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
    }
  },
  // 资讯管理 新建 -> 编辑
  {
    path: '/info-manage/info-list/info-form/:id?',
    component: () => import('../views/info-manage/info-list/info-form.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
    }
  },
  {
    path: '/',
    component: () => import('../views/layout'),
    meta: {
      requiresAuth: true
    },
    redirect: '/index',
    children: [
      // 首页
      {
        path: 'index',
        component: () => import('../views/index'),
        meta: {
          nav: 'index',
          role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
        }
      },
      // 信用评价与监管
      {
        path: 'org-credit',
        component: () => import('../views/org-credit'),
        meta: {
          role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
        }
      },
      // 账号管理
      {
        path: 'account-manage',
        component: () => import('../views/account-manage'),
        meta: {
          nav: 'account-manage',
          role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1, globalVar.ADMIN_ROLE_2]
        },
        children: [
          {
            path: 'user-list',
            component: () => import('../views/account-manage/user-list/index.vue'),
            meta: {
              nav: 'account-manage',
              sidebar: '/account-manage/user-list',
              role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1, globalVar.ADMIN_ROLE_2]
            }
          },
          {
            path: 'rz-audit',
            component: () => import('../views/account-manage/rz-audit/index.vue'),
            meta: {
              nav: 'account-manage',
              sidebar: '/account-manage/rz-audit',
              role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1, globalVar.ADMIN_ROLE_2]
            }
          },
          {
            path: 'jcs-audit',
            component: () => import('../views/account-manage/jcs-audit/index.vue'),
            meta: {
              nav: 'account-manage',
              sidebar: '/account-manage/jcs-audit',
              role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1, globalVar.ADMIN_ROLE_2]
            }
          },
          {
            path: 'admin-manage',
            component: () => import('../views/account-manage/admin-manage/index.vue'),
            meta: {
              nav: 'account-manage',
              sidebar: '/account-manage/admin-manage',
              role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
            }
          }
        ]
      },
      // 资源/需求
      {
        path: 'resource-require',
        component: () => import('../views/resource-require'),
        redirect: '/resource-require/service',
        meta: {
          nav: 'resource-require',
          role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1, globalVar.ADMIN_ROLE_2]
        },
        children: [
          // 服务资源
          {
            path: 'service',
            component: () => import('../views/resource-require/service/index.vue'),
            meta: {
              nav: 'resource-require',
              sidebar: '/resource-require/service',
              role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1, globalVar.ADMIN_ROLE_2]
            }
          },
          // 需求库
          {
            path: 'require',
            component: () => import('../views/resource-require/require/index.vue'),
            meta: {
              nav: 'resource-require',
              sidebar: '/resource-require/require',
              role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1, globalVar.ADMIN_ROLE_2]
            }
          },
          // 专家
          // {
          //   path: 'expert',
          //   component: () => import('../views/resource-require/expert/index.vue'),
          //   meta: {
          //     nav: 'resource-expert',
          //     sidebar: '/resource-require/expert',
          //     role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
          //   }
          // },
          {
            // 成果
            path: 'achievement',
            component: () => import('../views/resource-require/achievement/index.vue'),
            meta: {
              nav: 'resource-achievement',
              sidebar: '/resource-require/achievement',
              role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1, globalVar.ADMIN_ROLE_2]
            }
          },
          // 服务类型维护
          {
            path: 'service-type-manage',
            component: () => import('../views/resource-require/service-type-manage/index.vue'),
            meta: {
              nav: 'resource-require',
              sidebar: '/resource-require/service-type-manage',
              role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
            }
          }
        ]
      },
      // 服务链管理
      {
        path: 'service-chain-manage',
        component: () => import('../views/service-chain-manage'),
        meta: {
          nav: 'service-chain-manage',
          role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
        }
      },
      // 订单管理
      {
        path: 'order-manage',
        component: () => import('../views/order-manage'),
        redirect: '/order-manage/service-order',
        meta: {
          nav: 'order-manage',
          role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
        },
        children: [
          {
            path: 'service-order',
            component: () => import('../views/order-manage/service-order'),
            meta: {
              nav: 'order-manage',
              sidebar: '/order-manage/service-order',
              role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
            }
          },
          {
            path: 'require-order',
            component: () => import('../views/order-manage/require-order'),
            meta: {
              nav: 'order-manage',
              sidebar: '/order-manage/require-order',
              role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
            }
          }
        ]
      },
      // 订单反馈
      {
        path: 'order-feedback',
        component: () => import('../views/order-feedback'),
        redirect: '/order-feedback/comment',
        meta: {
          nav: 'order-feedback',
          role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
        },
        children: [
          // 订单反馈
          {
            path: 'comment',
            component: () => import('../views/order-feedback/comment'),
            meta: {
              nav: 'order-feedback',
              sidebar: '/order-feedback/comment',
              role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
            }
          },
          // 用户举报
          {
            path: 'report',
            component: () => import('../views/order-feedback/report'),
            meta: {
              nav: 'order-feedback',
              sidebar: '/order-feedback/report',
              role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
            }
          }
        ]
      },
      // 资讯管理
      {
        path: 'info-manage',
        component: () => import('../views/info-manage'),
        redirect: '/info-manage/info-stats/',
        meta: {
          nav: 'info-manage',
          role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
        },
        children: [
          // 资讯统计
          {
            path: 'info-stats',
            component: () => import('../views/info-manage/info-stats'),
            meta: {
              nav: 'info-stats',
              sidebar: '/info-manage/info-stats',
              role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
            }
          },
          // 资讯列表
          {
            path: 'info-list',
            component: () => import('../views/info-manage/info-list'),
            meta: {
              nav: 'info-list',
              sidebar: '/info-manage/info-list',
              role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
            }
          },
          // 资讯审核
          {
            path: 'info-check',
            component: () => import('../views/info-manage/info-check'),
            meta: {
              nav: 'info-check',
              sidebar: '/info-manage/info-check',
              role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
            }
          }
        ]
      },
      // 技术经纪人首页/数据监控
      {
        path: 'data',
        component: () => import('../views/data'),
        meta: {
          nav: 'data',
          role: [globalVar.ADMIN_ROLE_0, globalVar.ADMIN_ROLE_1]
        }
      }
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = cookie.get(globalVar.CMS_TOKEN)
    if (!token) {
      next({
        path: '/login'
      })
    } else {
      const userinfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}
      const role = userinfo.role
      // 没有role或者有权限，则通过
      if (to.meta.role && !to.meta.role.includes(role)) {
        next('/login')
      } else {
        next()
      }
    }
  } else {
    next() // 确保一定要调用 next()
  }
})

router.afterEach((to, from) => {
})

export default router
