import { render, staticRenderFns } from "./detail-header.vue?vue&type=template&id=096703d0&scoped=true&"
import script from "./detail-header.vue?vue&type=script&lang=js&"
export * from "./detail-header.vue?vue&type=script&lang=js&"
import style0 from "./detail-header.vue?vue&type=style&index=0&id=096703d0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "096703d0",
  null
  
)

export default component.exports