<template>
  <div id="app" class="page_min_width">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'app',
  created () {
    this.$store.dispatch('getTechnicalList')
    this.$store.dispatch('getIndustrySectorList')
  }
}
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  overflow: auto;
}
@import './assets/styles/index.scss';
@import url('//at.alicdn.com/t/font_2324264_z5r0ei02ywb.css');
</style>
