<template>
  <div class="header tw-flex tw-items-center">
    <i class="iconfont header_icon tw-flex tw-justify-center tw-items-center" :class="icon"></i>
    <p class="header_label text_size_16 tw-font-bold">{{label}}</p>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    label: String
  }
}
</script>

<style lang="scss" scoped>
.header {
  padding: 0 20px;
  &_icon {
    width: 30px;
    height: 30px;
    color: #ffffff;
    background-color: #90B7F1;
    border-radius: 4px;
  }
  &_label {
    margin-left: 10px;
    line-height: 22px;
    color: #31394C;
  }
}
</style>
