import Vue from 'vue'
import clone from 'clone'
import App from './App.vue'
import router from './router'
import store from './store'

import './plugins/element.js'
import './plugins/components'
import './plugins/vuescroll'
import './plugins/filter'
import './plugins/mixin'

// deep clone
Vue.prototype.$clone = clone
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
