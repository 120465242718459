// 全局的过滤器
import Vue from 'vue'
import dayjs from 'dayjs'

// 默认：YYYY-MM-DD 若需要其他格式 则传递第二个参数
Vue.filter('dateFormat', (input, pattern = 'YYYY-MM-DD') => {
  return dayjs(input).format(pattern)
})

// 默认：YYYY-MM-DD 若需要其他格式 则传递第二个参数
Vue.filter('mobileFormat', (mobile) => {
  if (mobile) {
    return mobile.replace(/^(\d{3})\d{4}(\d+)/, '$1****$2')
  }
  return ''
})

// 传入一个Options 根据value找到label
Vue.filter('findLabel', (input, options = [], prop = { label: 'label', value: 'value' }) => {
  const item = options.find(item => {
    return item[prop.value] === input
  })

  return item ? item[prop.label] : ''
})
